import {Range} from 'react-range'
import {useState} from 'react'

import {fieldType} from '../../../lib/types'
import RangeTrack from './RangeTrack'
import RangeThumb from './RangeThumb'

const RangePercentageField = ({
  question,
  currentAnswer,
  setCurrentAnswer,
}) => {
  const [values, setValues] = useState(currentAnswer.length !== 0
    ? [parseInt(currentAnswer[0].title)]
    : [0])
  const [tooltipText, setTooltipText] = useState(
    currentAnswer.length !== 0
      ? currentAnswer[0].title
      : 0,
  )

  const {answers} = question

  const MIN = answers.length !== 0 ? parseInt(answers[0].title) : 0
  const MAX = answers.length !== 0 ? parseInt(answers[answers.length - 1].title) : 100

  const between = (x, min, max) => {
    return x >= min && x < max
  }

  const getAnswer = () => {
    for (let index = 0; index < answers.length; index++) {
      if (index === answers.length - 1) {
        setCurrentAnswer([answers[index]])
        break
      } else {
        if (between(values[0], parseInt(answers[index].title), parseInt(answers[index + 1].title))) {
          setCurrentAnswer([answers[index]])
          break
        }
      }
    }
  }

  return (
    <div className="mt-36">
      <Range
        min={MIN}
        max={MAX}
        values={values}
        onChange={(values) => {
          setValues(values)
          setTooltipText(`${values[0]}${values[0] === MAX ? '+' : ''}%`)
        }}
        onFinalChange={() => {
          getAnswer()
        }}
        renderTrack={RangeTrack}
        renderThumb={({props}) => (
          <div {...props} className="relative">
            <div
              className="absolute -top-20 border-2 border-blue-800 rounded-lg px-3 py-3.5 utility-button-label-lg text-blue-800 arrow-down arrow-down--blue bg-white !tracking-widest"
              aria-live="assertive">
              <span>{tooltipText}</span>
            </div>
            <RangeThumb/>
          </div>
        )}
      />
      <p className="body-md mt-10 text-gray-500 text-center">
        (slide toggle left or right to select)
      </p>
      <div aria-live="assertive" className="sr-only">{tooltipText}</div>
    </div>
  )
}

RangePercentageField.propTypes = fieldType

export default RangePercentageField
