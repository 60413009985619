import { questionType } from '../../lib/types'
import PropTypes from 'prop-types'

const Interstitial = ({ step, goToStep, getIndex, children }) => {
  return (
    <div className="flex flex-col items-center text-center">
      <svg className="w-32 mb-10 h-28 text-blue-800" aria-hidden="true">
        <use href="#sprite-high-five-simple"></use>
      </svg>

      <h1 className="mb-4 headline-lg">{step?.title}</h1>

      <div
        className="mb-8 prose prose-lg text-gray-500 md:prose-2xl"
        dangerouslySetInnerHTML={{ __html: step?.description }}
      />

      <div className="flex gap-6 md:gap-4 shrink-0">
        {step?.backTo && (
          <button
            className="btn btn--outline-secondary w-36"
            onClick={() => {
              goToStep(getIndex(step.backTo))
            }}
          >
            Back
          </button>
        )}

        {step?.nextTo && (
          <button
            className="w-full btn btn--secondary w-36"
            onClick={() => {
              goToStep(getIndex(step.nextTo))
            }}
          >
            Next
          </button>
        )}
      </div>

      {children}
    </div>
  )
}

Interstitial.propTypes = {
  step: questionType.isRequired,
  goToStep: PropTypes.func.isRequired,
  getIndex: PropTypes.func.isRequired,
  children: PropTypes.element,
}

export default Interstitial
