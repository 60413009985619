import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import PropTypes from 'prop-types'

const DeleteAccountModal = ({
  openDeleteModal,
  setOpenDeleteModal,
  isLoading,
  flashMessage,
  errorMessage,
  deleteAccount,
}) => {
  return (
    <Transition.Root show={openDeleteModal} as={Fragment}>
      <Dialog as="div" className="relative z-20" onClose={setOpenDeleteModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-600/90" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:pb-8">
                {flashMessage && (
                  <div className="p-2 rounded-lg bg-green utility-button-label-sm !leading-normal text-white mb-3">
                    {flashMessage}
                  </div>
                )}
                {errorMessage && (
                  <div className="p-2 rounded-lg bg-red-500 utility-button-label-sm !leading-normal text-white mb-3">
                    {errorMessage}
                  </div>
                )}
                <div className="mt-3 text-center text-gray-600 sm:mt-0 sm:text-left">
                  <Dialog.Title as="h3" className="!font-black headline-sm">
                    Confirm Account Deletion
                  </Dialog.Title>
                  <p className="body-lg mt-2.5">
                    Are you sure you want to delete your account?
                  </p>
                </div>
                <div className="flex items-center gap-4 mt-8">
                  <button
                    type="button"
                    className="btn btn--md btn--primary"
                    onClick={deleteAccount}
                    disabled={isLoading}
                  >
                    Confirm
                  </button>
                  <button
                    type="button"
                    className="btn btn--text-link"
                    onClick={() => setOpenDeleteModal(false)}
                    disabled={isLoading}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

DeleteAccountModal.propTypes = {
  openDeleteModal: PropTypes.bool.isRequired,
  setOpenDeleteModal: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  flashMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  deleteAccount: PropTypes.func.isRequired,
}

export default DeleteAccountModal
