import { createRoot } from 'react-dom/client'
import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'

import { useLocalStorage } from '../hooks/useLocalStorage'
import { PRETIREMENT_FAVORITED_ACTIONS } from '../lib/constants'

import ActionCard from '../components/ActionCard'
import { actionItemType } from '../lib/types'

const YourList = ({ actionItems }) => {
  const [favoritedActions, setFavoritedActions] = useLocalStorage(
    PRETIREMENT_FAVORITED_ACTIONS,
    [],
  )

  const [actions, setActions] = useState([])

  useEffect(() => {
    setActions(
      actionItems.filter(actionItem =>
        favoritedActions.includes(actionItem.id),
      ),
    )
  }, [actionItems, favoritedActions])

  const favoriteAction = (id) => {
    if (favoritedActions.includes(id)) {
      setFavoritedActions(favoritedActions =>
        favoritedActions.filter(item => item !== id),
      )
    } else {
      setFavoritedActions([...favoritedActions, id])
    }
  }

  return (
    <div className="relative">
      <div className="container flex flex-col gap-6 pb-16 lg:gap-16 lg:flex-row md:pb-24 xl:pb-32">
        <div className="w-full lg:w-1/5">
          {favoritedActions.length !== 0 && (
            <a
              href="/sign-up"
              className="btn btn--primary btn--icon-right w-full !tracking-normal mb-4 hidden lg:flex"
            >
              <span>Create my plan</span>
              <span className="bg-white text-red-500 py-1 px-2 rounded-full">
                {favoritedActions.length}
              </span>
            </a>
          )}

          <a
            href="/browse-all"
            className="btn btn--outline-secondary w-full hidden lg:block"
          >
            BACK TO BROWSING
          </a>
        </div>

        <div className="w-full lg:w-4/5 flex flex-col">
          <ul className="grid gap-6 md:grid-cols-2 xl:grid-cols-3">
            {actions &&
              actions.map(actionItem => (
                <ActionCard
                  key={actionItem.id}
                  data={actionItem}
                  showFavorite
                  favoriteAction={favoriteAction}
                  favorited={favoritedActions.includes(actionItem.id)}
                />
              ))}
          </ul>

          <a
            href="/browse-all"
            className="btn btn--outline-secondary w-full lg:hidden max-w-sm self-center mt-12"
          >
            BACK TO BROWSING
          </a>
        </div>
      </div>

      {favoritedActions.length !== 0 && (
        <div className="lg:hidden floating-action-container">
          <a
            href="/sign-up"
            className="btn btn--primary btn--icon-right w-full !tracking-normal max-w-sm self-center"
          >
            <span>Create my plan</span>
            <span className="bg-white text-red-500 py-1 px-2 rounded-full">
              {favoritedActions.length}
            </span>
          </a>
        </div>
      )}
    </div>
  )
}

export default YourList

YourList.propTypes = {
  actionItems: PropTypes.arrayOf(actionItemType).isRequired,
}

if (document.getElementById('your-list')) {
  const container = document.getElementById('your-list')

  const props = Object.assign({}, container.dataset)
  const root = createRoot(container)

  props.actionItems = JSON.parse(props.actionItems)

  root.render(<YourList {...props} />)
}
