import {createRoot} from 'react-dom/client'
import {useForm} from 'react-hook-form'
import PropTypes from 'prop-types'
import {useState} from 'react'

import {LoginUser} from '../lib/api'

const Login = ({hero}) => {
  const [flashMessage, setFlashMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState([])

  const {
    register,
    handleSubmit,
    formState: {isDirty, isValid, errors, isSubmitting},
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: '',
    },
  })

  const onSubmit = async (data) => {
    try {
      const {flashMessage, redirect, response} = await LoginUser({
        email: data.email,
      })
      if (redirect) {
        window.location = redirect
      } else if (flashMessage) {
        reset()
        setErrorMessage([])
        setFlashMessage(flashMessage || '')
      } else if (response && response.status === 400) {
        if (response.data.flashMessage) {
          setErrorMessage([])
          setFlashMessage(response.data.flashMessage)
        } else {
          setFlashMessage('')
          setErrorMessage([response.data.message || 'There was an error. Please try again.'])
        }
      }
    } catch (error) {
      console.log('error', error)
      setFlashMessage('')
      setErrorMessage(['There was an error. Please try again.'])
    }
  }

  return (
    <section className="container flex flex-col gap-6 pt-10 pb-16 lg:gap-16 lg:flex-row md:pb-24 xl:pb-32">
      <div className="flex flex-col w-full py-8 border border-gray-300 rounded-lg lg:w-4/5 md:py-10 md:px-16 px-7">
        <h1 className="mb-4 text-gray-500 headline-md">
          {hero.heroTitle}
        </h1>
        <div className="flex flex-col justify-between h-full gap-10 lg:flex-row">
          <div
            className="prose prose-lg text-gray-500 md:prose-2xl"
            dangerouslySetInnerHTML={{__html: hero.heroBody}}
          />

          <div className="hidden h-full line line--gray lg:block"/>

          <form className="w-full lg:w-[35%] shrink-0" onSubmit={handleSubmit(onSubmit)}>
            {flashMessage && (
              <div
                className="py-2 px-3 rounded-3xl bg-green utility-button-label-sm !leading-normal text-white mb-3 arrow-down arrow-down--green text-center">
                {flashMessage}
              </div>
            )}

            {errorMessage.length !== 0 && (
              <ul
                className="py-2 px-3 rounded-3xl bg-red-500 utility-button-label-sm !leading-normal text-white mb-3  arrow-down arrow-down--red text-center">
                {errorMessage.map((error, index) => (
                  <li key={index}>
                    {error}
                  </li>
                ))}
              </ul>
            )}

            <div className="mb-8">
              <label htmlFor="email" className="utility-button-label-md">
                your email
              </label>
              <input
                {...register('email', {required: true})}
                type="email"
                placeholder="name@domain.com"
                className="w-full mt-3 border border-gray-600 placeholder:text-gray-400 focus:border-blue-800 focus:outline-none focus:ring-0 focus:border-2"
              />
              {errors.email && (
                <span className="mt-2 text-red-500 utility-button-label-sm">
                  A valid email address is required
                </span>
              )}
            </div>

            <div className="mt-8">
              <button
                type="submit"
                className="w-full btn btn--primary"
                disabled={!isDirty || !isValid || isSubmitting}
              >
                login
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="hidden w-full lg:w-1/5 lg:block shrink-0">
        <img
          src={hero.image}
          alt={hero.imageAlt}
          aria-hidden="true"
        />
      </div>
    </section>
  )
}

Login.propTypes = {
  hero: PropTypes.shape({
    heroTitle: PropTypes.string.isRequired,
    heroBody: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    imageAlt: PropTypes.string.isRequired,
  }).isRequired,
}

export default Login

if (document.getElementById('login')) {
  const container = document.getElementById('login')

  const props = Object.assign({}, container.dataset)
  const root = createRoot(container)

  props.hero = JSON.parse(props.hero)

  root.render(<Login {...props} />)
}
