import {createRoot} from 'react-dom/client'
import PropTypes from 'prop-types'
import {useEffect, useState} from 'react'

import ActionCard from '../components/ActionCard'

import {useLocalStorage} from '../hooks/useLocalStorage'
import {PRETIREMENT_FAVORITED_ACTIONS} from '../lib/constants'
import {actionItemType} from '../lib/types'

const YourActionPlan = ({ actionItems, hero }) => {
  const [quizActions] = useLocalStorage(PRETIREMENT_FAVORITED_ACTIONS, [])

  const [actions, setActions] = useState([])

  useEffect(() => {
    if (quizActions.length === 0) {
      window.location.replace(`/quiz`)
    }

    setActions(
      actionItems.filter(actionItem => quizActions.includes(actionItem.id)),
    )
  }, [actionItems, quizActions])

  return (
    <>
      <div className="container flex items-center gap-8 pt-4 pb-10 md:pb-12 md:pt-10 lg:gap-16">
        <div className="w-full md:w-2/3 lg:w-1/2">
          <h1 className="mb-6 headline-jumbo">{hero.heroTitle}</h1>

          <div 
            className="prose prose-lg text-gray-500 md:prose-2xl"
            dangerouslySetInnerHTML={{__html: hero.heroBody}}
          />

          <a href="/sign-up" className="mt-8 btn btn--primary">
            SAVE YOUR PLAN
          </a>
        </div>

        <div className="hidden w-full md:w-1/3 lg:w-1/2 md:block ">
          <img
            src={hero.image}
            alt={hero.imageAlt}
            aria-hidden="true"
            className="ml-auto"
          />
        </div>
      </div>

      <div className="container flex flex-col gap-6 pb-16 lg:gap-16 md:pb-24 xl:pb-32">
        <ul className="grid gap-6 md:grid-cols-2 xl:grid-cols-3">
          {actions &&
            actions.map(actionItem => (
              <ActionCard key={actionItem.id} data={actionItem}/>
            ))}
        </ul>

        <a href="/sign-up" className="self-center w-full max-w-sm btn btn--primary">
          save your plan
        </a>
      </div>
    </>
  )
}

YourActionPlan.propTypes = {
  actionItems: PropTypes.arrayOf(actionItemType).isRequired,
  hero: PropTypes.shape({
    heroTitle: PropTypes.string.isRequired,
    heroBody: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    imageAlt: PropTypes.string.isRequired,
  }).isRequired,
}

export default YourActionPlan

if (document.getElementById('your-action-plan')) {
  const container = document.getElementById('your-action-plan')

  const props = Object.assign({}, container.dataset)
  const root = createRoot(container)

  props.actionItems = JSON.parse(props.actionItems)
  props.hero = JSON.parse(props.hero)

  root.render(<YourActionPlan {...props} />)
}
