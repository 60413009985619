import PropTypes from 'prop-types'

const StepHeader = ({title, description}) => {
  return (
    <legend className="text-center mb-10">
      <h1 className="headline-md">{title}</h1>
      <div
        className="text-gray-500 mt-4"
        dangerouslySetInnerHTML={{__html: description}}
      />
    </legend>
  )
}

StepHeader.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
}

export default StepHeader
