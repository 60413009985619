import {fieldType} from '../../../lib/types'

const RadioField = ({question, currentAnswer, setCurrentAnswer}) => {
  return (
    <div className="flex flex-col gap-4 items-center" role="radiogroup">
      {question?.answers &&
        question?.answers.map(answer => (
          <div key={answer.id}>
            <input
              type="radio"
              id={answer.id}
              name={question.id}
              value={answer.id}
              className="peer h-0 w-0 opacity-0 focus:outline-none"
              onChange={() => {
                setCurrentAnswer([answer])
              }}
              checked={
                currentAnswer.length !== 0
                  ? currentAnswer[0].id === answer.id
                  : false
              }
            />
            <label
              htmlFor={answer.id}
              className="btn btn--outline-secondary font-normal cursor-pointer peer-checked:bg-blue-800 peer-checked:text-white w-80 sm:w-96 peer-focus-visible:ring peer-focus-visible:ring-blue-500 peer-focus-visible:ring-offset-2"
            >
              {answer.title}
            </label>
          </div>
        ))}
    </div>
  )
}

RadioField.propTypes = fieldType

export default RadioField
