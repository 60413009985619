import PropTypes from 'prop-types'

import FilterList from '../FilterList'

const FilterPanel = ({setShowFilter, actionTypes, filters, setFilters}) => {
  return (
    <div className="lg:hidden floating-action-container">
      <button
        className="self-end"
        onClick={() => setShowFilter(false)}
        aria-label={`Close Filters Panel`}
      >
        <svg className="w-4 h-4" aria-hidden="true">
          <use href="#sprite-close"></use>
        </svg>
      </button>

      <FilterList
        actionTypes={actionTypes}
        filters={filters}
        setFilters={setFilters}
        setShowFilter={setShowFilter}
      />
    </div>
  )
}

FilterPanel.propTypes = {
  setShowFilter: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(PropTypes.string),
  setFilters: PropTypes.func.isRequired,
  actionTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default FilterPanel
