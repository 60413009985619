import PropTypes from 'prop-types'
import { useState } from 'react'

import { DeleteUser } from '../lib/api'

import DeleteAccountModal from './DeleteAccountModal'
import { useLocalStorage } from '../hooks/useLocalStorage'
import { PRETIREMENT_USER } from '../lib/constants'

const DeleteAccountSection = ({ token, deleteAccountText }) => {
  const [, setPretirementUser, clearPretirementUser] = useLocalStorage(
    PRETIREMENT_USER,
    '',
  )

  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [flashMessage, setFlashMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const deleteAccount = async () => {
    setIsLoading(true)
    setErrorMessage('')
    setFlashMessage('')

    try {
      let response = await DeleteUser({ id: token })

      if (response.message === 'User Data deleted') {
        setErrorMessage('')
        clearPretirementUser()
        setTimeout(() => {
          window.location.replace(`/after-delete-account`)
          setIsLoading(false)
        }, 500)
      } else {
        setIsLoading(false)
        console.log('response', response)
        setFlashMessage('')
        setErrorMessage('There was an error. Please try again.')
      }
    } catch (error) {
      setIsLoading(false)
      console.log('error', error)
      setFlashMessage('')
      setErrorMessage('There was an error. Please try again.')
    }
  }

  return (
    <>
      <div className="flex flex-col justify-between md:flex-row md:items-center">
        <div
          className="max-w-md mb-8 body-md md:mb-0 lg:max-w-xs xl:max-w-xl"
          dangerouslySetInnerHTML={{ __html: deleteAccountText }}
        />

        <button className="btn btn--text-link btn--icon-left" onClick={() => setOpenDeleteModal(true)}>
          <svg className="w-7 h-7" aria-hidden="true">
            <use href="#sprite-trash"></use>
          </svg>
          <span>Delete Account</span>
        </button>
      </div>


      <DeleteAccountModal
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        deleteAccount={deleteAccount}
        isLoading={isLoading}
        flashMessage={flashMessage}
        errorMessage={errorMessage}
      />
    </>
  )
}

DeleteAccountSection.propTypes = {
  token: PropTypes.string.isRequired,
  deleteAccountText: PropTypes.string.isRequired,
}

export default DeleteAccountSection
