import { fieldType } from '../../../lib/types'

const CheckboxField = ({ question, currentAnswer, setCurrentAnswer }) => {
  const handleOnChange = (option) => {
    if (currentAnswer.find(answer => answer.id === option.id)) {
      setCurrentAnswer(items => items.filter(item => item.id !== option.id))
    } else {
      setCurrentAnswer([...currentAnswer, option])
    }
  }

  return (
    <div className="flex flex-col gap-4 items-center">
      {question?.answers &&
        question?.answers.map(answer => (
          <div key={answer.id}>
            <input
              type="checkbox"
              id={answer.id}
              name={answer.id}
              value={answer.id}
              className="peer h-0 w-0 opacity-0 focus:outline-none block"
              checked={!!currentAnswer.find(ans => ans.id === answer.id)}
              onChange={() => handleOnChange(answer)}
            />
            <label
              htmlFor={answer.id}
              className="font-normal btn btn--icon-left btn--outline-secondary cursor-pointer peer-checked:bg-blue-800 peer-checked:text-white w-80 sm:w-96 [&>svg]:text-gray-300 [&>svg]:peer-checked:text-blue-800 peer-focus-visible:bg-blue-500 peer-focus-visible:text-white"
            >
              <svg
                className="w-5 h-5 shrink-0 text-gray-300"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <use href="#sprite-checkbox-circle"></use>
              </svg>
              {answer.title}
            </label>
          </div>
        ))}
    </div>
  )
}

CheckboxField.propTypes = fieldType

export default CheckboxField
