import {createRoot} from 'react-dom/client'
import PropTypes from 'prop-types'
import {useEffect, useState, useCallback} from 'react'

import {GetUser, MarkActionComplete} from '../lib/api'
import {useLocalStorage} from '../hooks/useLocalStorage'
import {
  PRETIREMENT_FAVORITED_ACTIONS,
  PRETIREMENT_USER,
} from '../lib/constants'

import CopyLink from '../components/CopyLink'
import DetailsActionPanel from '../components/FloatingPanels/DetailsActionPanel'

const ActionDetail = ({entry}) => {
  const [showFavoriteFloatingAction, setShowFavoriteFloatingAction] =
    useState(true)
  const [favoritedActions, setFavoritedActions] = useLocalStorage(
    PRETIREMENT_FAVORITED_ACTIONS,
    [],
  )
  const [pretirementUser] = useLocalStorage(PRETIREMENT_USER)
  const [userData, setUserData] = useState()
  const [currentUserAction, setCurrentUserAction] = useState(null)

  const retrieveUserData = useCallback(() => {
    const getUser = async (id) => {
      const { userData } = await GetUser({
        id: id,
      })
      if (userData) {
        setUserData(userData)
        setCurrentUserAction(
          userData?.actions?.find(action => action.id === entry.id),
        )
      }
    }

    if (pretirementUser) {
      getUser(pretirementUser).catch(console.error)
    }
  }, [entry.id, pretirementUser])

  useEffect(() => {
    retrieveUserData()
  }, [retrieveUserData])

  const favoriteAction = (id) => {
    setFavoritedActions([...favoritedActions, id])
  }

  const setActionToComplete = async (action) => {
    await MarkActionComplete({
      token: userData.token,
      action,
    })
    retrieveUserData()
  }

  const dashboardLink = userData && `/dashboard?token=${userData.token}`
  const actionCompleted = currentUserAction && currentUserAction?.completed !== 0
  const addedToFavorites = favoritedActions.includes(entry.id)

  return (
    <div className="relative">
      <div
        className="container flex flex-col items-start justify-center gap-6 pt-10 pb-16 md:flex-row md:pb-24 xl:pb-32">
        <div className="w-full lg:w-1/2">
          <div className="px-6 py-8 border border-gray-300 rounded-lg lg:px-16 lg:py-10">
            <h1 className="mb-4 headline-md">{entry.title}</h1>

            <ul className="flex flex-wrap gap-2">
              {entry.actionTypes.map(actionType => (
                <li key={actionType.id}>
                  <a 
                    className="block px-5 rounded-full py-2.5 utility-button-label-md focus:outline-none focus-visible:outline focus-visible:outline-blue-800 focus-visible:outline-offset-2 btn--outline-secondary"
                    href={`/browse-all?category=${actionType.title}`}>
                    {actionType.title}
                  </a>
                </li>
              )) }
            </ul>

            <div className="prose prose-lg text-gray-500 md:prose-2xl">
              <div dangerouslySetInnerHTML={{__html: entry.excerpt}}/>
              <div dangerouslySetInnerHTML={{__html: entry.description}}/>
            </div>
          </div>
          <div className="flex-wrap items-center justify-center hidden gap-10 mt-10 md:flex">
            {currentUserAction ? (
              <>
                <button
                  className="btn btn--primary"
                  disabled={actionCompleted}
                  onClick={() => setActionToComplete(currentUserAction.id)}
                >
                  {actionCompleted
                    ? 'completed'
                    : 'mark as complete'}{' '}
                  <span className="sr-only">, {entry.title}</span>
                </button>
                <a href={dashboardLink} className="btn btn--text-link">
                  back to my plan
                </a>
              </>
            ) : (
              <>
                <button
                  className="btn btn--primary"
                  disabled={addedToFavorites}
                  onClick={() => favoriteAction(entry.id)}
                >
                  {addedToFavorites ? 'Added' : 'Add'} to my
                  plan
                  <span className="sr-only">, {entry.title}</span>
                </button>
                <button onClick={() => window.history.back()} className="btn btn--text-link">
                  Back to Browse
                </button>
              </>
            )}
          </div>
        </div>

        {entry.draftEmail && (
          <div className="w-full px-6 py-8 bg-gray-100 rounded-lg lg:w-1/2 lg:py-9 lg:px-7">
            <div className="flex justify-between">
              <h3 className="headline-sm">Talking Points</h3>
              <CopyLink text={entry.draftEmail} srText={'email draft'}/>
            </div>
            <div
              className="prose prose-lg text-gray-600 md:prose-2xl mt-7"
              dangerouslySetInnerHTML={{__html: entry.draftEmail}}
            />
          </div>
        )}

        <div className="self-center md:hidden">
          {currentUserAction ? (
            <a href={dashboardLink} className="btn btn--text-link">
              back to my plan
            </a>
          ) : (
            <button onClick={() => window.history.back()} className="btn btn--text-link">
              Back to Browse
            </button>
          )}
        </div>
      </div>

      {showFavoriteFloatingAction && (
        <DetailsActionPanel
          setShowFavoriteFloatingAction={setShowFavoriteFloatingAction}
          entry={entry}
          favoriteAction={favoriteAction}
          addedToFavorites={addedToFavorites}
          currentUserAction={currentUserAction}
          actionCompleted={actionCompleted}
          setActionToComplete={setActionToComplete}
        />
      )}
    </div>
  )
}

ActionDetail.propTypes = {
  entry: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    excerpt: PropTypes.string,
    description: PropTypes.string,
    draftEmail: PropTypes.string,
    actionTypes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
}

export default ActionDetail

if (document.getElementById('action-detail')) {
  const container = document.getElementById('action-detail')

  const props = Object.assign({}, container.dataset)
  const root = createRoot(container)

  props.entry = JSON.parse(props.entry)

  root.render(<ActionDetail {...props} />)
}
