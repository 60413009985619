import {useState} from 'react'
import PropTypes from 'prop-types'

import {userType} from '../lib/types'
import {copyTextToClipboard} from '../lib/utils'

const ActionPlanHeader = ({user, hero}) => {
  const [isCopied, setIsCopied] = useState(false)

  const {actions, token} = user

  const dashboardLink = `https://${window.location.hostname}/dashboard?token=${token}`

  const handleCopyClick = () => {
    copyTextToClipboard(dashboardLink)
      .then(() => {
        setIsCopied(true)
        setTimeout(() => {
          setIsCopied(false)
        }, 3000)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const completedActions = actions.filter(action => action.completed !== 0)

  return (
    <>
      <div className="container flex items-center gap-8 pt-4 pb-10 md:pb-12 md:pt-10 lg:gap-16">
        <div className="w-full lg:w-1/2">
          {(actions.length !== 0 && completedActions.length === actions.length) ? (
            <>
              <h1 className="mb-6 headline-jumbo">
                Congratulations, {user.firstName}! You’re all done.
              </h1>

              <div
                className="mb-10 prose prose-lg text-gray-500 md:prose-2xl"
                dangerouslySetInnerHTML={{__html: hero.heroCompletedBody}}
              />

              <a
                href="https://aarp.com"
                target="_blank"
                rel="noopener noreferrer"
                className="max-w-sm btn btn--primary"
              >
                explore aarp.com
              </a>
            </>
          ) : (
            <>
              <h1 className="mb-6 headline-jumbo">
                {hero.heroTitle}, {user.firstName}.
              </h1>

              <div
                className="mb-10 prose prose-lg text-gray-500 md:prose-2xl"
                dangerouslySetInnerHTML={{__html: hero.heroBody}}
              />

              <p className="mb-6 font-bold text-center text-gray-500 md:text-left">
                Save your unique link for your dashboard:
              </p>

              <div className="flex flex-wrap items-center justify-center gap-4 md:justify-start">
                <button
                  onClick={() => handleCopyClick()}
                  className="max-w-sm truncate btn btn--secondary"
                >
                  {dashboardLink}
                </button>
                {isCopied ? (
                  <div className="text-blue-800 btn btn--icon-left !p-0">
                    <svg className="w-6 h-6" aria-hidden="true">
                      <use href="#sprite-copied"></use>
                    </svg>
                    <span>Copied</span>
                  </div>
                ) : (
                  <button
                    className="btn btn--text-link btn--icon-left"
                    onClick={() => handleCopyClick()}
                  >
                    <svg className="w-7 h-7" aria-hidden="true">
                      <use href="#sprite-copy"></use>
                    </svg>
                    <span>Copy</span>
                    <span className="sr-only"> Dashboard Link</span>
                  </button>
                )}
              </div>
            </>
          )}
        </div>

        <div className="hidden lg:w-1/2 lg:block">
          <img
            src={hero.image}
            alt={hero.imageAlt}
            aria-hidden="true"
          />
        </div>
      </div>
    </>
  )
}

ActionPlanHeader.propTypes = {
  user: userType.isRequired,
  hero: PropTypes.shape({
    heroTitle: PropTypes.string.isRequired,
    heroBody: PropTypes.string.isRequired,
    heroCompletedBody: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    imageAlt: PropTypes.string.isRequired,
  }).isRequired,
}

export default ActionPlanHeader
