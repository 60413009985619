import PropTypes from 'prop-types'
import ApiClient from './apiClient'

const client = new ApiClient({
  baseUrl: '/actions/userdata/',
  ...window.CSRF_CONFIG,
})

/**
 * User API
 *
 * - RegisterUser
 * - GetUser
 * - UpdateUser
 * - DeleteUser
 */
const RegisterUser = ({ firstName, email, actions = [] }) =>
  client.request('user/register', { firstName, email, actions })

RegisterUser.propTypes = {
  firstName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  actions: PropTypes.array,
}
const GetUser = async ({ id }) =>
  client.request(`user/fetch`, { token: id })

GetUser.propTypes = {
  id: PropTypes.string.isRequired,
}

const UpdateUser = ({ firstName, email }) =>
  client.request('user/update', { firstName, email })

UpdateUser.propTypes = {
  firstName: PropTypes.string,
  email: PropTypes.string,
}

const DeleteUser = ({ id }) =>
  client.request('user/delete', { token: id })

DeleteUser.propTypes = {
  id: PropTypes.string,
}

/**
 * Auth API
 */
const LoginUser = ({ email }) =>
  client.request('auth/login', { email })

LoginUser.propTypes = {
  email: PropTypes.string,
}

const SendEmailVerificationEmail = ({ token }) =>
  client.request('auth/send-verification-email', { token })

SendEmailVerificationEmail.propTypes = {
  token: PropTypes.string,
}

/**
 * Savings Goal API
 *
 * SaveSavingsGoal
 * MarkSavingsGoalComplete
 */
const SaveSavingsGoal = ({ token, savingsGoal, savingsGoalMonths }) =>
  client.request('action/set-savings-goal', { token, savingsGoal, savingsGoalMonths })

SaveSavingsGoal.propTypes = {
  token: PropTypes.string.isRequired,
  savingsGoal: PropTypes.number.isRequired,
  savingsGoalMonths: PropTypes.number.isRequired,
}

const MarkSavingsGoalComplete = ({ token }) =>
  client.request('action/toggle-savings-goal-completion', { token })

MarkSavingsGoalComplete.propTypes = {
  token: PropTypes.string.isRequired,
}

/**
 * Action API
 * MarkActionComplete
 * RemoveActionFromPlan
 * AddActions
 */
const MarkActionComplete = ({ token, action }) =>
  client.request('action/toggle-completion', { token, action })

MarkActionComplete.propTypes = {
  token: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
}

const RemoveActionFromPlan = ({ token, action }) =>
  client.request('action/remove', { token, action })

RemoveActionFromPlan.propTypes = {
  token: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
}

const AddActions = ({ token, action }) =>
  client.request('action/add', { token, action })

AddActions.propTypes = {
  token: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
}

export {
  LoginUser,
  RegisterUser,
  GetUser,
  UpdateUser,
  DeleteUser,
  SaveSavingsGoal,
  MarkSavingsGoalComplete,
  MarkActionComplete,
  RemoveActionFromPlan,
  AddActions,
}
