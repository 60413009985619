import {useState} from 'react'
import PropTypes from 'prop-types'

import {copyTextToClipboard} from '../lib/utils'

const CopyLink = ({text, srText}) => {
  const [isCopied, setIsCopied] = useState(false)

  const handleCopyClick = () => {
    copyTextToClipboard(text)
      .then(() => {
        setIsCopied(true)
        setTimeout(() => {
          setIsCopied(false)
        }, 3000)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return isCopied ? (
    <div className="text-blue-800 btn btn--icon-left !p-0">
      <svg className="w-6 h-6" aria-hidden="true">
        <use href="#sprite-copied"></use>
      </svg>
      <span>Copied</span>
    </div>
  ) : (
    <button
      className="btn btn--text-link btn--icon-left"
      onClick={() => handleCopyClick()}
    >
      <svg className="w-7 h-7" aria-hidden="true">
        <use href="#sprite-copy"></use>
      </svg>
      <span>Copy</span>
      <span className="sr-only"> {srText}</span>
    </button>
  )
}

CopyLink.propTypes = {
  text: PropTypes.string.isRequired,
  srText: PropTypes.string.isRequired,
}

export default CopyLink
