import PropTypes from 'prop-types'

const EmptyState = ({ state }) => {
  return (
    <div className="px-5 py-10 text-center md:py-16 lg:mx-auto">
      <div className="mx-auto md:max-w-3xl">
        {state === 'No Todos' && (
          <>
            <h2 className="mb-4 headline-xs">
              You haven’t selected any items to work on
            </h2>
            <p className="mb-6 text-gray-500 body-md">
              Browse more action items and add them to your action plan to get
              started.
            </p>
          </>
        )}

        {state === 'None Completed' && (
          <>
            <h2 className="mb-4 headline-xs">
              You haven’t completed any items yet
            </h2>
            <p className="mb-6 text-gray-500 body-md">
              When you mark an action item as completed, it will show up here.
            </p>
          </>
        )}

        {state === 'Todos Completed' && (
          <>
            <h2 className="mb-4 headline-xs">Well Done!</h2>
            <p className="mb-6 text-gray-500 body-md">
              You’ve made your retirement more secure by completing all your
              action items.
            </p>
          </>
        )}

        <a href="/browse-all" className="btn btn--outline-secondary">
          browse more
        </a>
      </div>
    </div>
  )
}

EmptyState.propTypes = {
  state: PropTypes.oneOf(['No Todos', 'None Completed', 'Todos Completed']).isRequired,
}

export default EmptyState
