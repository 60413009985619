import PropTypes from 'prop-types'

const ToggleFilterButton = ({label, tab, activeTab, setActiveTab}) => {
  return (
    <button
      className={`w-1/2 utility-button-label-md py-3 rounded-full focus:outline-none focus-visible:outline focus-visible:outline-blue-800 focus-visible:outline-offset-2 ${
        activeTab ? 'text-white bg-blue-800' : 'bg-white text-blue-800 hover:bg-blue-500 hover:text-white'
      }`}
      onClick={() => setActiveTab(tab)}
    >
      {label}
    </button>
  )
}

ToggleFilterButton.propTypes = {
  label: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired,
  activeTab: PropTypes.bool.isRequired,
  setActiveTab: PropTypes.func.isRequired,
}

export default ToggleFilterButton
