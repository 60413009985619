import PropTypes from 'prop-types'

const SubscribeCallout = ({token}) => {
  return (
    <div className="bg-blue-300 px-8 py-10 md:py-[60px] text-center lg:mx-auto">
      <div className="mx-auto md:max-w-3xl">
        <h2 className="mb-4 headline-sm font-black">Ready to Bring Your Plan to Life?</h2>
        <p className="mb-6 text-gray-500 body-md">
          Sign up to get your personalized plan delivered to your inbox—and get
          tips and tricks for staying on track from AARP experts.
        </p>
        <a
          href={`https://secure.aarp.org/applications/user/customSubscribe?promo=ACEYR${
            token ? '&aceId=' + token : ''
          }`}
          className="btn btn--secondary"
          target="_blank"
          rel="noreferrer"
        >
          Subscribe
          <span className="sr-only"> to a newsletter from AARP</span>
        </a>
      </div>
    </div>
  )
}

SubscribeCallout.propTypes = {
  token: PropTypes.string,
}

export default SubscribeCallout
