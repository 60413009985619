import PropTypes from 'prop-types'

import FilterList from './FilterList'

const BrowseAllSidebar = ({
  favoritedActionsCount,
  userFound,
  updateUserActions,
  setShowFilter,
  showFilter,
  setShowMobileFilter,
  actionTypes,
  filters,
  setFilters,
}) => {
  return (
    <>
      {(favoritedActionsCount !== 0 && !userFound) && (
        <a
          href="/your-list"
          className="btn btn--primary btn--icon-right w-full !tracking-normal mb-4 hidden lg:flex"
        >
          <span>view my items</span>
          <span className="px-2 py-1 text-red-500 bg-white rounded-full">
            {favoritedActionsCount}
          </span>
        </a>
      )}

      {(favoritedActionsCount !== 0 && userFound) && (
        <button
          onClick={updateUserActions}
          className="btn btn--primary btn--icon-right w-full !tracking-normal mb-4 hidden lg:flex"
        >
          <span>add to plan</span>
          <span className="px-2 py-1 text-red-500 bg-white rounded-full">
            {favoritedActionsCount}
          </span>
        </button>
      )}

      <button
        className="w-full btn btn--secondary btn--icon-right lg:hidden"
        onClick={() => setShowMobileFilter(true)}
      >
        <span>Filter</span>
        <svg className="w-6 h-6" aria-hidden="true">
          <use href="#sprite-filter"></use>
        </svg>
      </button>

      <div
        className={`border border-blue-800 px-2.5 hidden lg:block ${
          showFilter ? 'pb-5 rounded-lg' : 'rounded-full focus-within:outline focus-within:outline-blue-800 focus-within:outline-offset-2'
        }`}
      >
        <button
          className="w-full text-blue-800 btn btn--icon-right focus-visible:outline-none"
          onClick={() => {
            setShowFilter(!showFilter)
          }}
        >
          <span>
                Filter{' '}
            {(showFilter || filters.length !== 0) && (
              <span>({filters.length})</span>
            )}
          </span>

          {showFilter ? (
            <svg className="w-4 h-4" aria-hidden="true">
              <use href="#sprite-arrow-up"></use>
            </svg>
          ) : (
            <svg className="w-4 h-4" aria-hidden="true">
              <use href="#sprite-arrow-down"></use>
            </svg>
          )}
        </button>

        {showFilter && (
          <div className="mt-4">
            <FilterList
              actionTypes={actionTypes}
              filters={filters}
              setFilters={setFilters}
            />
          </div>
        )}
      </div>
    </>
  )
}

BrowseAllSidebar.propTypes = {
  favoritedActionsCount: PropTypes.number.isRequired,
  userFound: PropTypes.bool.isRequired,
  updateUserActions: PropTypes.func.isRequired,
  setShowFilter: PropTypes.func.isRequired,
  showFilter: PropTypes.bool.isRequired,
  setShowMobileFilter: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(PropTypes.string),
  setFilters: PropTypes.func.isRequired,
  actionTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default BrowseAllSidebar
