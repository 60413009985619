import {createRoot} from 'react-dom/client'
import {useForm} from 'react-hook-form'
import PropTypes from 'prop-types'
import {useState} from 'react'

import {RegisterUser} from '../lib/api'
import {useLocalStorage} from '../hooks/useLocalStorage'
import {PRETIREMENT_FAVORITED_ACTIONS} from '../lib/constants'
import ConfirmEmailModal from '../components/ConfirmEmailModal'

const SignUp = ({hero}) => {
  const [favoritedActions, setFavoritedActions] = useLocalStorage(
    PRETIREMENT_FAVORITED_ACTIONS,
    [],
  )
  const [flashMessage, setFlashMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState([])
  const [openModal, setOpenModal] = useState(false)

  const {
    register,
    handleSubmit,
    getValues,
    formState: {isDirty, isValid, errors, isSubmitting},
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: '',
      firstName: '',
    },
  })

  const onSubmit = async () => {
    setOpenModal(false)
    try {
      const {
        response,
        userData,
        flashMessage,
        redirect,
      } = await RegisterUser({
        actions: favoritedActions,
        email: getValues('email'),
        firstName: getValues('firstName'),
      })

      if (redirect) {
        window.location = redirect
      } else if (userData) {
        reset()
        setFavoritedActions([])
        setErrorMessage([])
        setFlashMessage(flashMessage || '')
      } else if (response.status === 400) {
        setFlashMessage('')
        setErrorMessage(response.data.errors.email)
      }
    } catch (error) {
      console.log('error', error)
      setFlashMessage('')
      setErrorMessage(['There was an error. Please try again.'])
    }

  }

  const confirmEmail = () => {
    setOpenModal(true)
  }

  return (
    <>
      <section className="container flex flex-col gap-6 pt-10 pb-16 lg:gap-16 lg:flex-row md:pb-24 xl:pb-32">
        <div className="flex flex-col w-full py-8 border border-gray-300 rounded-lg lg:w-4/5 md:py-10 md:px-16 px-7">
          <h1 className="mb-4 text-gray-500 headline-md">
            {hero.heroTitle}
          </h1>
          <div className="flex flex-col justify-between h-full gap-10 lg:flex-row">
            <div
              className="prose prose-lg text-gray-500 md:prose-2xl"
              dangerouslySetInnerHTML={{__html: hero.heroBody}}
            />

            <div className="hidden h-full line line--gray lg:block"/>

            <form className="w-full lg:w-[35%] shrink-0" onSubmit={handleSubmit(confirmEmail)}>
              {flashMessage && (
                <div
                  className="py-2 px-3 rounded-3xl bg-green utility-button-label-sm !leading-normal text-white mb-3 arrow-down arrow-down--green text-center">
                  {flashMessage}
                </div>
              )}

              {errorMessage.length !== 0 && (
                <ul
                  className="py-2 px-3 rounded-3xl bg-red-500 utility-button-label-sm !leading-normal text-white mb-3  arrow-down arrow-down--red text-center">
                  {errorMessage.map((error, index) => (
                    <li key={index}>
                      {error}
                      {error.includes('already been taken') && (
                        <a href="/log-in" className="underline">&nbsp;Login Here</a>
                      )}
                    </li>
                  ))}
                </ul>
              )}

              <div className="mb-4">
                <label htmlFor="name" className="utility-button-label-md">
                  your first name
                </label>
                <input
                  {...register('firstName', {required: true})}
                  type="text"
                  placeholder="Your First Name"
                  className="w-full mt-3 border border-gray-600 placeholder:text-gray-400 focus:border-blue-800 focus:outline-none focus:ring-0 focus:border-2"
                />
                {errors.firstName && (
                  <span className="mt-2 text-red-500 utility-button-label-sm">
                    This field is required
                  </span>
                )}
              </div>

              <div className="mb-8">
                <label htmlFor="email" className="utility-button-label-md">
                  your email
                </label>
                <input
                  {...register('email', {required: true})}
                  type="email"
                  placeholder="name@domain.com"
                  className="w-full mt-3 border border-gray-600 placeholder:text-gray-400 focus:border-blue-800 focus:outline-none focus:ring-0 focus:border-2"
                />
                {errors.email && (
                  <span className="mt-2 text-red-500 utility-button-label-sm">
                    A valid email address is required
                  </span>
                )}
              </div>

              <div className="mt-8">
                <button
                  type="submit"
                  className="w-full btn btn--primary"
                  disabled={!isDirty || !isValid || isSubmitting}
                >
                  sign up
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="hidden w-full lg:w-1/5 lg:block shrink-0">
          <img
            src={hero.image}
            alt={hero.imageAlt}
            aria-hidden="true"
          />
        </div>
      </section>
      <ConfirmEmailModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        email={getValues('email')}
        onSubmit={onSubmit}
      />
    </>
  )
}

SignUp.propTypes = {
  hero: PropTypes.shape({
    heroTitle: PropTypes.string.isRequired,
    heroBody: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    imageAlt: PropTypes.string.isRequired,
  }).isRequired,
}

export default SignUp

if (document.getElementById('sign-up')) {
  const container = document.getElementById('sign-up')

  const props = Object.assign({}, container.dataset)
  const root = createRoot(container)

  props.hero = JSON.parse(props.hero)

  root.render(<SignUp {...props} />)
}
