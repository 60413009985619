import PropTypes from 'prop-types'

import ProgressBar from '../ProgressBar'

const WizardFooter = ({
  isFirstStep,
  isLastStep,
  percentage,
  previousStep,
  nextStep,
  nextStepDisabled,
  hasPrevious,
}) => {
  return (
    <>
      {!isFirstStep && (
        <div className="w-full mt-10 text-center md:hidden">
          <ProgressBar percentage={percentage} />
        </div>
      )}
      <div className="fixed bottom-0 left-0 flex items-center justify-center w-full gap-6 p-5 bg-white shadow-top md:justify-between">
        <div className="hidden w-full md:block">
          {!isFirstStep && <ProgressBar percentage={percentage} />}
        </div>

        <div className="flex gap-x-6 md:gap-x-4 shrink-0">
          {!isFirstStep && (
            <button
              className="btn btn--outline-secondary w-36"
              onClick={() => previousStep()}
              disabled={isFirstStep || !hasPrevious}
            >
              Back
            </button>
          )}

          {!isLastStep && (
            <button
              className="w-full btn btn--secondary w-36"
              onClick={() => nextStep()}
              disabled={nextStepDisabled}
            >
              Next
            </button>
          )}
        </div>
      </div>
    </>
  )
}

WizardFooter.propTypes = {
  isFirstStep: PropTypes.bool.isRequired,
  isLastStep: PropTypes.bool.isRequired,
  percentage: PropTypes.number.isRequired,
  previousStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  nextStepDisabled: PropTypes.bool.isRequired,
  hasPrevious: PropTypes.bool.isRequired,
}

export default WizardFooter
