import axios from 'axios'

/**
 * Api Client
 */
export default class ApiClient {
  constructor({ baseUrl, csrfTokenName, csrfTokenValue}) {
    this.baseUrl = baseUrl
    this.csrfTokenName = csrfTokenName
    this.csrfTokenValue = csrfTokenValue
    // Track in progress requests so that we can cancel them if needed
    this.requests = {}
  }
  /**
   * Perform an API request
   * @param route
   * @param requestData
   * @returns {Promise<*>}
   */
  async request(route, requestData = {}) {
    try {
      this.cancelIdenticalRequestIfExists({ route, requestData })

      const signal = this.onStart({ route, requestData })

      const { data } = await axios.post(
        `${this.baseUrl}${route}`,
        {
          ...requestData,
        },
        {
          headers: {
            Accept: 'application/json',
            ContentType: 'application/json',
            'X-CSRF-Token': this.csrfTokenValue,
          },
          signal,
        },
      )
      this.onEnd({ route, requestData })

      return data
    } catch (error) {
      this.onEnd({ route, requestData })
      // @todo: handle errors!!!
      return error
    }
  }
  cancelIdenticalRequestIfExists(params) {
    if (this.requests[JSON.stringify(params)]) {
      this.requests[JSON.stringify(params)].abort()
    }
  }

  onStart(params) {
    const controller = new AbortController()

    this.requests[JSON.stringify(params)] = controller

    return controller.signal
  }

  onEnd(params) {
    this.requests[JSON.stringify(params)] = null
  }
}
