import PropTypes from 'prop-types'

const SkipStepButton = ({ skip }) => {
  return (
    <div className="mt-4 w-fit mx-auto">
      <button className="btn btn--text-link" onClick={skip}>
        skip this question
      </button>
    </div>
  )
}

SkipStepButton.propTypes = {
  skip: PropTypes.func.isRequired,
}

export default SkipStepButton
