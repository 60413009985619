import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import PropTypes from 'prop-types'

const ConfirmEmailModal = ({ openModal, setOpenModal, email, onSubmit }) => {
  return (
    <Transition.Root show={openModal} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={setOpenModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-600/90" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:pb-8">
                <div className="mt-3 text-center text-gray-600 sm:mt-0 sm:text-left">
                  <Dialog.Title as="h3" className="!font-black headline-sm">
                    Confirm Account Creation
                  </Dialog.Title>
                  <p className="body-md mt-2.5">
                    You registered with <span className="font-bold">{email}</span>. Please verify your email is correct and click the confirm button. If you would like to change it, click the link to change the email address.
                  </p>
                </div>
                <div className="flex items-center gap-4 mt-8">
                  <button
                    type="button"
                    className="btn btn--md btn--primary"
                    onClick={onSubmit}
                  >
                    Confirm
                  </button>
                  <button
                    type="button"
                    className="btn btn--text-link"
                    onClick={() => setOpenModal(false)}
                  >
                    Change Email Address
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

ConfirmEmailModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default ConfirmEmailModal

