import { useState } from 'react'
import PropTypes from 'prop-types'

import SocialCallout from './SocialCallout'
import SubscribeCallout from './SubscribeCallout'
import ActionProgressHeader from './ActionProgressHeader'
import ActionPlanGrid from './ActionPlanGrid'
import DeleteAccountSection from './DeleteAccountSection'

import { actionItemType, userType } from '../lib/types'
import { RemoveActionFromPlan } from '../lib/api'

const ActionPlanSection = ({ user, retrieveUserData, actionItems, deleteAccountText }) => {
  const [editing, setEditing] = useState(false)
  const [activeTab, setActiveTab] = useState('todo')
  const [itemsToDelete, setItemsToDelete] = useState([])

  const { actions, token } = user

  const completedActions = actions.filter(action => action.completed !== 0)
  const todoActions = actions.filter(action => action.completed === 0)
  const progress =
    actions.length !== 0 ? Number(((completedActions.length / actions.length) * 100).toFixed(0)) : 0

  const removeAction = async () => {
    await RemoveActionFromPlan({
      token,
      action: itemsToDelete,
    })
    retrieveUserData()
    setItemsToDelete([])
    setEditing(false)
  }

  const addActionToBeDeleted = async (action) => {
    setItemsToDelete([...itemsToDelete, action])
  }

  const cancelEdit = () => {
    setItemsToDelete([])
    setEditing(false)
  }

  return (
    <section className="container flex flex-col gap-16 pt-10 pb-16 lg:flex-row md:pb-24 xl:pb-32">
      {/* <div className="hidden w-full lg:block lg:w-1/3 xl:w-1/5"> */}
      {/*<GoalSettingSidebar*/}
      {/*  token={token}*/}
      {/*  savingsGoal={Number(savingsGoal)}*/}
      {/*  savingsGoalMonths={savingsGoalMonths}*/}
      {/*  savingsGoalCompleted={Boolean(savingsGoalCompleted)}*/}
      {/*  retrieveUserData={retrieveUserData}*/}
      {/*/>*/}
      {/* <SubscribeCallout token={token} />
      </div> */}

      <div className="w-full line line--gray lg:hidden" />

      <div className="w-full">
        <ActionProgressHeader
          editing={editing}
          setEditing={setEditing}
          completedActions={completedActions.length}
          todoActions={todoActions.length}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          progress={progress}
          itemsToDelete={itemsToDelete}
          removeAction={removeAction}
          cancelEdit={cancelEdit}
        />

        <div className="hidden w-full line line--gray lg:block" />

        {activeTab === 'todo' && (
          <ActionPlanGrid
            actions={todoActions}
            editing={editing}
            state="No Todos"
            actionItems={actionItems}
            itemsToDelete={itemsToDelete}
            addActionToBeDeleted={addActionToBeDeleted}
            activeTab={activeTab}
            allActionsCompleted={
              actions.length !== 0 && completedActions.length === actions.length
            }
            allActionsAdded={
              actionItems.length === actions.length
            }
          />
        )}

        {activeTab === 'completed' && (
          <ActionPlanGrid
            actions={completedActions}
            editing={editing}
            state="None Completed"
            actionItems={actionItems}
            itemsToDelete={itemsToDelete}
            addActionToBeDeleted={addActionToBeDeleted}
            allActionsCompleted={
              actions.length !== 0 || completedActions.length === actions.length
            }
            allActionsAdded={
              actionItems.length === actions.length
            }
            activeTab={activeTab}
          />
        )}

        <div className="lg:hidden">
          <SubscribeCallout token={token} />
        </div>

        <SocialCallout />

        <div className="w-full mb-16 line line--gray" />

        <DeleteAccountSection token={token} deleteAccountText={deleteAccountText} />
      </div>
    </section>
  )
}

ActionPlanSection.propTypes = {
  user: userType.isRequired,
  actionItems: PropTypes.arrayOf(actionItemType).isRequired,
  retrieveUserData: PropTypes.func,
  deleteAccountText: PropTypes.string.isRequired,
}

export default ActionPlanSection
