import PropTypes from 'prop-types'

import ProgressBar from './ProgressBar'
import ToggleFilterButton from './ToggleFilterButton'

const ActionProgressHeader = ({
  editing,
  setEditing,
  completedActions,
  todoActions,
  activeTab,
  setActiveTab,
  progress,
  removeAction,
  itemsToDelete,
  cancelEdit,
}) => {
  return (
    <div className="flex flex-col gap-6 mb-5 md:justify-between md:items-end md:flex-row">
      <div>
        <div className="flex flex-wrap items-center justify-between gap-5 md:justify-start">
          <h2 className="headline-lg">Your Action Plan</h2>
          {editing ? (
            <div className="flex gap-3">
              <button
                className="btn btn--md btn--primary"
                onClick={() => {
                  removeAction()
                }}
                disabled={itemsToDelete.length === 0}
              >
                Save
              </button>
              <button
                onClick={() => cancelEdit()}
                className="btn btn--md btn--outline-secondary"
              >
                Cancel
              </button>
            </div>
          ) : (
            <button
              onClick={() => setEditing(true)}
              className="btn btn--text-link btn--icon-left"
            >
              <svg className="w-5 h-5" viewBox="0 0 24 24" aria-hidden="true">
                <use href="#sprite-edit"></use>
              </svg>
              <span>Edit</span>
              <span className="sr-only"> your action plan</span>
            </button>
          )}
        </div>

        {completedActions !== 0 && (
          <div className="mt-6">
            <ProgressBar percentage={progress}/>
          </div>
        )}
      </div>

      <div
        className="p-2 border border-blue-800 rounded-full shrink-0 flex items-center justify-center gap-2 min-w-[330px]">
        <ToggleFilterButton
          label={`to-do(${todoActions})`}
          tab="todo"
          activeTab={activeTab === 'todo'}
          setActiveTab={setActiveTab}
        />
        <ToggleFilterButton
          label={`complete(${completedActions})`}
          tab="completed"
          activeTab={activeTab === 'completed'}
          setActiveTab={setActiveTab}
        />
      </div>
    </div>
  )
}

ActionProgressHeader.propTypes = {
  editing: PropTypes.bool.isRequired,
  setEditing: PropTypes.func.isRequired,
  completedActions: PropTypes.number.isRequired,
  todoActions: PropTypes.number.isRequired,
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  progress: PropTypes.number.isRequired,
  removeAction: PropTypes.func.isRequired,
  itemsToDelete: PropTypes.arrayOf(PropTypes.string).isRequired,
  cancelEdit: PropTypes.func.isRequired,
}

export default ActionProgressHeader
