import PropTypes from 'prop-types'

const ProgressBar = ({ percentage }) => {
  return (
    <>
      <p className="mb-3 utility-button-label-md text-left">your progress</p>
      <div className="flex items-center gap-3">
        <p className="text-blue-500 headline-sm">{percentage + '%'}</p>
        <div className="w-full h-2 bg-gray-200 rounded-full max-w-[242px]">
          <div
            className="h-2 bg-blue-500 rounded-full"
            style={{ width: percentage + '%' }}
          />
        </div>
      </div>
    </>
  )
}

ProgressBar.propTypes = {
  percentage: PropTypes.number.isRequired,
}

export default ProgressBar
