const RangeThumb = () => {
  return (
    <div className="flex justify-between text-blue-800 items-center px-1 h-9 w-16 bg-yellow border-2 border-blue-800 rounded-full">
      <svg
        className="w-5 h-4 -rotate-90"
        viewBox="0 0 24 24"
        aria-hidden="true"
      >
        <use href="#sprite-arrow"></use>
      </svg>
      <svg
        className="w-5 h-4 rotate-90"
        viewBox="0 0 24 24"
        aria-hidden="true"
      >
        <use href="#sprite-arrow"></use>
      </svg>
    </div>
  )
}

export default RangeThumb
