import { createRoot } from 'react-dom/client'
import { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'

import ActionPlanHeader from '../components/ActionPlanHeader'
import ActionPlanSection from '../components/ActionPlanSection'

import { useLocalStorage } from '../hooks/useLocalStorage'
import { GetUser } from '../lib/api'
import { PRETIREMENT_USER } from '../lib/constants'
import { actionItemType } from '../lib/types'

const Dashboard = ({ actionItems, hero, deleteAccountText }) => {
  const [pretirementUser, setPretirementUser] = useLocalStorage(
    PRETIREMENT_USER,
    '',
  )
  const [userData, setUserData] = useState()

  const retrieveUserData = useCallback(() => {
    const params = new URLSearchParams(window.location.search)
    const token = params.get('token')

    const getUser = async (id) => {
      const { userData } = await GetUser({
        id: id,
      })

      if (userData) {
        setUserData(userData)
      } else {
        window.location.replace(`/`)
      }
    }

    if (token) {
      getUser(token).catch(console.error)
      setPretirementUser(token)
    } else if (pretirementUser) {
      getUser(pretirementUser).catch(console.error)
    } else {
      setPretirementUser('')
      window.location.replace(`/`)
    }
  }, [])

  useEffect(() => {
    retrieveUserData()
  }, [retrieveUserData])

  return (
    <>
      {userData && (
        <>
          <ActionPlanHeader
            user={userData}
            hero={hero}
          />
          <ActionPlanSection
            user={userData}
            actionItems={actionItems}
            retrieveUserData={retrieveUserData}
            deleteAccountText={deleteAccountText}
          />
        </>
      )}
    </>
  )
}

export default Dashboard

Dashboard.propTypes = {
  actionItems: PropTypes.arrayOf(actionItemType).isRequired,
  hero: PropTypes.shape({
    heroTitle: PropTypes.string.isRequired,
    heroBody: PropTypes.string.isRequired,
    heroCompletedBody: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    imageAlt: PropTypes.string.isRequired,
  }).isRequired,
  deleteAccountText: PropTypes.string.isRequired,
}

if (document.getElementById('dashboard')) {
  const container = document.getElementById('dashboard')

  const props = Object.assign({}, container.dataset)
  const root = createRoot(container)

  props.actionItems = JSON.parse(props.actionItems)
  props.hero = JSON.parse(props.hero)

  root.render(<Dashboard {...props} />)
}
