import PropTypes from 'prop-types'

const FavoriteActionPanel = ({favoritedActionsCount, userFound, updateUserActions, setShowFavoriteFloatingAction}) => {
  return (
    <div className="lg:hidden floating-action-container">
      {favoritedActionsCount !== 0 && !userFound && (
        <a
          href="/your-list"
          className="btn btn--primary btn--icon-right w-full !tracking-normal max-w-sm self-center"
        >
          <span>view my items</span>
          <span className="px-2 py-1 text-red-500 bg-white rounded-full">
            {favoritedActionsCount}
          </span>
        </a>
      )}

      {favoritedActionsCount !== 0 && userFound && (
        <button
          onClick={updateUserActions}
          className="btn btn--primary btn--icon-right w-full !tracking-normal max-w-sm self-center"
        >
          <span>Add to Plan</span>
          <span className="px-2 py-1 text-red-500 bg-white rounded-full">
            {favoritedActionsCount}
          </span>
        </button>
      )}

      {favoritedActionsCount === 0 && (
        <div className="self-center max-w-sm text-red-800 bg-white border border-red-800 btn btn--icon-left">
          <svg
            className="w-10 h-10 text-white fill-red-500 shrink-0"
            aria-hidden="true"
          >
            <use href="#sprite-favorite"></use>
          </svg>
          <span>favorite items to start building your plan</span>
        </div>
      )}
    </div>
  )
}

FavoriteActionPanel.propTypes = {
  favoritedActionsCount: PropTypes.number.isRequired,
  userFound: PropTypes.bool.isRequired,
  updateUserActions: PropTypes.func.isRequired,
  setShowFavoriteFloatingAction: PropTypes.func.isRequired,
}


export default FavoriteActionPanel
