import PropTypes from 'prop-types'

const DetailsActionPanel = ({ setShowFavoriteFloatingAction, currentUserAction, setActionToComplete, favoriteAction, entry, addedToFavorites, actionCompleted }) => {
  return (
    <div className="floating-action-container md:hidden">
      <button
        className="self-end"
        aria-label={`Close Add to Plan panel`}
        onClick={() => setShowFavoriteFloatingAction(false)}
      >
        <svg className="w-4 h-4" aria-hidden="true">
          <use href="#sprite-close"></use>
        </svg>
      </button>

      {currentUserAction ? (
        <button
          className="btn btn--primary"
          disabled={actionCompleted}
          onClick={() => setActionToComplete(currentUserAction.id)}
        >
          {actionCompleted
            ? 'completed'
            : 'mark as complete'}{' '}
          <span className="sr-only">, {entry.title}</span>
        </button>
      ) : (
        <button
          className="btn btn--primary"
          disabled={addedToFavorites}
          onClick={() => favoriteAction(entry.id)}
        >
          {addedToFavorites ? 'Added' : 'Add'} to my plan
          <span className="sr-only">, {entry.title}</span>
        </button>
      )}
    </div>
  )
}

DetailsActionPanel.propTypes = {
  setShowFavoriteFloatingAction: PropTypes.func.isRequired,
  currentUserAction: PropTypes.shape({
    id: PropTypes.string.isRequired,
    completed: PropTypes.number.isRequired,
  }),
  setActionToComplete: PropTypes.func.isRequired,
  favoriteAction: PropTypes.func.isRequired,
  addedToFavorites: PropTypes.bool,
  actionCompleted: PropTypes.bool,
  entry: PropTypes.object,
}

export default DetailsActionPanel
