import {createRoot} from 'react-dom/client'
import PropTypes from 'prop-types'
import {useCallback, useEffect, useState} from 'react'

import ActionCard from '../components/ActionCard'
import BrowseAllSidebar from '../components/BrowseAllSidebar'
import FilterPanel from '../components/FloatingPanels/FilterPanel'
import FavoriteActionPanel from '../components/FloatingPanels/FavoriteActionPanel'

import {useLocalStorage} from '../hooks/useLocalStorage'
import {AddActions, GetUser} from '../lib/api'
import {PRETIREMENT_FAVORITED_ACTIONS, PRETIREMENT_USER} from '../lib/constants'
import {actionItemType} from '../lib/types'

const takeQuiz = new URL('~/img/take-quiz.png', import.meta.url)

if (import.meta.env.DEV) {
  takeQuiz.port = import.meta.env.VITE_DEV_SERVER_PORT ?? 3000
}

const ITEMS_PER_PAGE = 12

const BrowseActions = ({actionTypes, actionItems}) => {
  const [favoritedActions, setFavoritedActions] = useLocalStorage(
    PRETIREMENT_FAVORITED_ACTIONS,
    [],
  )
  const [pretirementUser] = useLocalStorage(PRETIREMENT_USER)
  const [userData, setUserData] = useState()
  const [items, setItems] = useState([...actionItems])
  const [showFilter, setShowFilter] = useState(true)
  const [showMobileFilter, setShowMobileFilter] = useState(false)
  const [showFavoriteFloatingAction, setShowFavoriteFloatingAction] =
    useState(true)
  const [filteredItems, setFilteredItems] = useState([...items])
  const [filters, setFilters] = useState([])
  const [numOfActionsToShow, setNumOfActionsToShow] = useState(ITEMS_PER_PAGE)

  const params = new URLSearchParams(window.location.search)
  const category = params.get('category')

  const retrieveUserData = useCallback(() => {
    const getUser = async (id) => {
      const { userData } = await GetUser({
        id: id,
      })
      if (userData) {
        setUserData(userData)
      }
    }

    if (pretirementUser) {
      getUser(pretirementUser).catch(console.error)
    }
  }, [])

  useEffect(() => {
    retrieveUserData()
  }, [retrieveUserData])

  useEffect(() => {
    if (category) {
      setFilters([...filters, ...[category]])
    }
  }, [category])

  useEffect(() => {
    setItems(
      actionItems.filter(actionItem =>
        !userData?.actions.some(action => action.id === actionItem.id),
      ),
    )
  }, [actionItems, userData])

  useEffect(() => {
    if (filters.length) {
      setFilteredItems(
        items.filter(actionItem =>
          actionItem?.actionType.some(actionType =>
            filters.includes(actionType?.type),
          ),
        ),
      )
    } else {
      setFilteredItems([...items])
    }
  }, [filters, items])

  const updateUserActions = async () => {
    await AddActions({
      token: userData.token,
      action: favoritedActions,
    })
    setFavoritedActions([])
    window.location.href = `/dashboard?token=${userData.token}`
  }

  const favoriteAction = (id) => {
    if (favoritedActions.includes(id)) {
      setFavoritedActions(items => items.filter(item => item !== id))
    } else {
      setFavoritedActions([...favoritedActions, id])
    }
  }

  const loadMore = () => {
    setNumOfActionsToShow(numOfActionsToShow + ITEMS_PER_PAGE)
  }

  return (
    <div className="relative">
      <div className="container flex flex-col gap-6 pb-16 lg:gap-16 lg:flex-row md:pb-24 xl:pb-32">
        <div className="w-full lg:w-1/5">
          <BrowseAllSidebar
            favoritedActionsCount={favoritedActions.length}
            userFound={Boolean(userData)}
            updateUserActions={updateUserActions}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            setShowMobileFilter={setShowMobileFilter}
            actionTypes={actionTypes}
            filters={filters}
            setFilters={setFilters}
          />

          <div className="flex-col items-center hidden w-full mt-20 lg:flex">
            <img
              src={takeQuiz.href}
              alt="Take a Quiz"
              className="object-cover max-w-md mx-auto"
            />
            <div className="max-w-sm mx-auto my-6 text-center">
              <h3 className="text-gray-600 headline-xs">
                Prefer to Take a Quiz Instead?
              </h3>
              <p className="text-gray-500 body-md">
                Rather than selected your goals, take this quick quiz and let our expertise handle the rest.
              </p>
            </div>
            <a className="mt-auto btn btn--primary " href="/quiz">Take the quiz</a>
          </div>
        </div>

        <div className="w-full lg:w-4/5">
          <ul className="grid gap-6 md:grid-cols-2 xl:grid-cols-3">
            {items &&
              filteredItems
                ?.slice(0, numOfActionsToShow)
                ?.map((actionItem, key) => (
                  <ActionCard
                    key={actionItem.id}
                    data={actionItem}
                    showFavorite
                    favoriteAction={favoriteAction}
                    favorited={favoritedActions.includes(actionItem.id)}
                    showFavoritePopup={favoritedActions.length === 0 && key === 0}
                  />
                ))}
          </ul>

          {numOfActionsToShow < filteredItems?.length && (
            <div className="mt-10 text-center">
              <button onClick={loadMore} className="btn btn--outline-secondary">
                Browse More
              </button>
            </div>
          )}

          <div className="flex flex-col items-center w-full mt-10 lg:hidden">
            <img
              src={takeQuiz.href}
              alt="Take a Quiz"
              className="object-cover max-w-md mx-auto"
            />
            <div className="max-w-sm mx-auto my-6 text-center">
              <h3 className="text-gray-600 headline-xs">
                Prefer to Take a Quiz Instead?
              </h3>
              <p className="text-gray-500 body-md">
                Rather than selected your goals, take this quick quiz and let our expertise handle the rest.
              </p>
            </div>
            <a className="mt-auto btn btn--primary " href="/quiz">Take the quiz</a>
          </div>
        </div>
      </div>

      {showFavoriteFloatingAction && (!showFilter || !showMobileFilter) && (
        <FavoriteActionPanel
          favoritedActionsCount={favoritedActions.length}
          userFound={Boolean(userData)}
          updateUserActions={updateUserActions}
          setShowFavoriteFloatingAction={setShowFavoriteFloatingAction}
        />
      )}

      {showMobileFilter && (
        <FilterPanel
          setShowFilter={setShowMobileFilter}
          actionTypes={actionTypes}
          filters={filters}
          setFilters={setFilters}
        />
      )}
    </div>
  )
}

BrowseActions.propTypes = {
  actionItems: PropTypes.arrayOf(actionItemType).isRequired,
  actionTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default BrowseActions

if (document.getElementById('browse-actions')) {
  const container = document.getElementById('browse-actions')

  const props = Object.assign({}, container.dataset)
  const root = createRoot(container)

  props.actionItems = JSON.parse(props.actionItems)
  props.actionTypes = JSON.parse(props.actionTypes)

  root.render(<BrowseActions {...props} />)
}
