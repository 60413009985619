const SocialCallout = () => {
  return (
    <div className="px-5 py-10 md:py-[60px] text-center -mx-8 lg:mx-auto">
      <div className="flex flex-col gap-6 mx-auto md:max-w-3xl">
        <h2 className="text-gray-500 headline-xs">
          This website does not provide advice. Consult your financial advisor
          for advice regarding your personal situation.
        </h2>
      </div>
    </div>
  )
}

export default SocialCallout
