import PropTypes from 'prop-types'

export const actionItemType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  excerpt: PropTypes.string,
  url: PropTypes.string,
})

export const userType = PropTypes.shape({
  firstName: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  savingsGoal: PropTypes.string,
  savingsGoalMonths: PropTypes.number,
  savingsGoalCompleted: PropTypes.number,
  actions: PropTypes.array,
})

export const answerType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  proceedsTo: PropTypes.string,
  actionItems: PropTypes.arrayOf(PropTypes.string),
})

export const questionType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  type: PropTypes.oneOf([
    'interstitial',
    'singleSelect',
    'steppedRange',
    'multiSelect',
    'percentageRange',
  ]).isRequired,
  answers: PropTypes.arrayOf(answerType),
  allowSkip: PropTypes.bool,
  backTo: PropTypes.string,
  nextTo: PropTypes.string,
  quizStep: PropTypes.string,
})

export const fieldType = {
  question: questionType.isRequired,
  currentAnswer: PropTypes.arrayOf(answerType),
  setCurrentAnswer: PropTypes.func.isRequired,
}
