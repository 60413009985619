import {Range} from 'react-range'
import {useState} from 'react'
import PropTypes from 'prop-types'

import {fieldType} from '../../../lib/types'
import RangeTrack from './RangeTrack'
import RangeThumb from './RangeThumb'

const Mark = ({props}) => (
  <div
    {...props}
    className="rounded-full bg-gray-300 h-4 w-3 flex items-center justify-center"
  >
    <div className="bg-blue-800 h-1.5 w-1.5 rounded-full"/>
  </div>
)

Mark.propTypes = {
  props: PropTypes.object,
}

const Thumb = ({props}) => (
  <div {...props} className="focus-visible:outline-none">
    <RangeThumb/>
  </div>
)

Thumb.propTypes = {
  props: PropTypes.object,
}

const RangeSegmentedField = ({question, currentAnswer, setCurrentAnswer}) => {
  const {answers} = question
  const [values, setValues] = useState(
    currentAnswer.length !== 0
      ? [answers.findIndex(answer => answer.id === currentAnswer[0].id)]
      : [0],
  )
  const [tooltipText, setTooltipText] = useState(
    currentAnswer.length !== 0
      ? answers[answers.findIndex(answer => answer.id === currentAnswer[0].id)].title
      : answers[0].title,
  )

  return (
    <div className="mt-36 relative">
      <div aria-live="assertive" className="sr-only">{tooltipText}</div>
      <div
        className="absolute -top-24 border-2 border-blue-800 rounded-lg px-3 py-3.5 utility-button-label-md md:utility-button-label-lg text-blue-800 bg-white !tracking-widest whitespace-nowrap w-fit left-0 right-0 mx-auto md:hidden">
        {tooltipText}
      </div>

      <Range
        min={0}
        max={answers.length - 1}
        values={values}
        onChange={(values) => {
          setValues(values)
          setTooltipText(answers[values[0]].title)
          setCurrentAnswer([answers[values[0]]])
        }}
        renderTrack={RangeTrack}
        renderMark={Mark}
        renderThumb={({props}) => (
          <div {...props} className="relative">
            <div
              className="absolute -top-24 border-2 border-blue-800 rounded-lg px-3 py-3.5 utility-button-label-md md:utility-button-label-lg text-blue-800 arrow-down arrow-down--blue bg-white !tracking-widest whitespace-nowrap w-fit left-1/2 -translate-x-1/2 translate-y-0 hidden md:block">
              {tooltipText}
            </div>
            <RangeThumb/>
          </div>
        )}
      />
      <div className="utility-button-label-sm flex justify-between text-gray-500 mt-8 text-center">
        <span className="w-16">Very Worried</span>
        <span className="w-16">Very Confident</span>
      </div>
      <p className="body-md mt-10 text-gray-500 text-center">
        (slide toggle left or right to select)
      </p>
    </div>
  )
}

RangeSegmentedField.propTypes = fieldType

export default RangeSegmentedField
