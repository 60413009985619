import PropTypes from 'prop-types'
import {useState, useEffect} from 'react'

import {actionItemType} from '../lib/types'

import ActionCard from './ActionCard'
import EmptyState from './EmptyState'

const ActionPlanGrid = ({
  activeTab,
  actions,
  editing,
  state,
  actionItems,
  addActionToBeDeleted,
  itemsToDelete,
  allActionsCompleted = false,
  allActionsAdded = false,
}) => {
  const [items, setItems] = useState([])

  useEffect(() => {
    setItems(
      actionItems.filter(actionItem =>
        actions.some(action => action.id === actionItem.id),
      ),
    )
  }, [actionItems, actions])

  if (actions.length > 0) {
    return (
      <div className="mt-8 mb-16">
        <ul className="grid gap-6 md:grid-cols-2 xl:grid-cols-3">
          {items.map(actionPlan =>
            !itemsToDelete.includes(actionPlan.id) && <ActionCard
              key={actionPlan.id}
              data={actionPlan}
              showDelete={editing}
              addActionToBeDeleted={addActionToBeDeleted}
            />,
          )}
        </ul>
        {(!allActionsAdded) && (
          <div className="flex justify-center mt-10">
            <a href="/browse-all" className="btn btn--outline-secondary">
              browse more
            </a>
          </div>
        )}
      </div>
    )
  } else if (activeTab === 'todo' && allActionsCompleted) {
    return <EmptyState state="Todos Completed" />
  } else {
    return <EmptyState state={state}/>
  }
}

ActionPlanGrid.propTypes = {
  actions: PropTypes.array.isRequired,
  editing: PropTypes.bool.isRequired,
  allActionsCompleted: PropTypes.bool,
  state: PropTypes.oneOf(['No Todos', 'None Completed']).isRequired,
  actionItems: PropTypes.arrayOf(actionItemType).isRequired,
  addActionToBeDeleted: PropTypes.func.isRequired,
  itemsToDelete: PropTypes.arrayOf(PropTypes.string),
  activeTab: PropTypes.string.isRequired,
  allActionsAdded: PropTypes.bool,
}

export default ActionPlanGrid
