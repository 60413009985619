import PropTypes from 'prop-types'

import {actionItemType} from '../lib/types'

const ActionCard = ({
  data,
  showDelete = false,
  showFavorite = false,
  favoriteAction,
  favorited,
  addActionToBeDeleted,
  showFavoritePopup = false,
}) => {
  return (
    <li className="relative flex flex-col py-8 border border-gray-300 lg:hover:border-blue-800 px-7">
      {showDelete && (
        <button
          className="absolute w-10 h-10 text-white -right-5 -top-5 lg:hover:text-red-800"
          aria-label={`Remove ${data.title} action item from plan`}
          onClick={() => addActionToBeDeleted(data.id)}
        >
          <svg viewBox="0 0 32 32" aria-hidden="true">
            <use href="#sprite-delete"></use>
          </svg>
        </button>
      )}

      <h2 className="mb-3 headline-xs">{data.title}</h2>

      <div
        className="mb-5 body-md"
        dangerouslySetInnerHTML={{__html: data.excerpt}}
      />

      <div className="flex items-center justify-between mt-auto">
        <a href={`/${data.url}`} className="btn btn--text-link">
          View Details{' '}
          <span className="sr-only">about {data.title}</span>
        </a>

        {showFavorite && (
          <button
            className={`relative lg:hover:text-white lg:hover:!fill-red-800 duration-150 transition-colors ${
              favorited
                ? '!fill-red-800 text-white'
                : 'text-red-800 !fill-white'
            }`}
            onClick={() => favoriteAction(data.id)}
          >
            {showFavoritePopup && <div
              className="absolute -top-16 bg-red-500 text-white py-3.5 px-7 z-20 rounded-[20px] utility-button-label-lg w-max arrow-down arrow-down--red left-1/2 -translate-x-1/2 translate-y-0 transition duration-150 hidden lg:block">
              <p>favorite items to start</p>
              <p>building your plan</p>
            </div>}
            <svg className="w-10 h-10" aria-hidden="true">
              <use href="#sprite-favorite"></use>
            </svg>
            <span className="sr-only">{favorited ? 'Un-favorite' : 'Favorite'}</span>
            <span className="sr-only"> {data.title}</span>
          </button>
        )}
      </div>
    </li>
  )
}

ActionCard.propTypes = {
  data: actionItemType.isRequired,
  showDelete: PropTypes.bool,
  showFavorite: PropTypes.bool,
  favoriteAction: PropTypes.func,
  favorited: PropTypes.bool,
  addActionToBeDeleted: PropTypes.func,
  showFavoritePopup: PropTypes.bool,
}

export default ActionCard
