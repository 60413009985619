import { createRoot } from 'react-dom/client'
import PropTypes from 'prop-types'
import { useState } from 'react'

import RadioField from '../components/Quiz/Fields/RadioField'
import CheckboxField from '../components/Quiz/Fields/CheckboxField'
import RangeSegmentedField from '../components/Quiz/Fields/RangeSegmentedField'
import RangePercentageField from '../components/Quiz/Fields/RangePercentageField'
import StepHeader from '../components/Quiz/StepHeader'
import WizardFooter from '../components/Quiz/WizardFooter'
import SkipStepButton from '../components/Quiz/SkipStepButton'
import Interstitial from '../components/Quiz/Interstitial'

import { questionType } from '../lib/types'
import { PRETIREMENT_FAVORITED_ACTIONS } from '../lib/constants'
import { useLocalStorage } from '../hooks/useLocalStorage'

const Quiz = ({ questions }) => {
  const [, setQuizActions] = useLocalStorage(PRETIREMENT_FAVORITED_ACTIONS)

  const isQuestionsValid = Array.isArray(questions) || questions.length === 0

  const [currentStep, setCurrentStep] = useState(0)
  const [stepHistory] = useState([])
  const [currentQuestion, setCurrentQuestion] = useState(
    isQuestionsValid ? questions[currentStep] : null,
  )
  const [currentAnswer, setCurrentAnswer] = useState([])

  const [answers, setAnswers] = useState(
    isQuestionsValid &&
    questions.map((question) => {
      return { id: question.id, answer: [] }
    }),
  )

  const addAnswer = () => {
    setAnswers((prevState) => {
      const newState = prevState.map((answer) => {
        if (answer.id === currentQuestion.id) {
          return { ...answer, answer: currentAnswer }
        }
        return answer
      })
      return newState
    })
  }

  const stepCount = questions.length
  const isFirstStep = currentQuestion && questions[0].id === currentQuestion.id
  const isLastStep =
    currentQuestion && questions[stepCount - 1].id === currentQuestion.id
  const percentage = isQuestionsValid
    ? Number((((currentStep + 1) / stepCount) * 100).toFixed(0))
    : 0
  const hasPrevious = stepHistory.length !== 0

  const getIndex = id => questions.findIndex(question => question.id === id)

  const goToStep = (stepIndex) => {
    if (stepIndex >= 0 && stepIndex < stepCount) {
      setCurrentStep(stepIndex)
      setCurrentQuestion(questions[stepIndex])
    } else {
      console.warn(
        [
          `Invalid step index [${stepIndex}] passed to 'goToStep'. `,
          `Ensure the given stepIndex is not out of boundaries.`,
        ].join(''),
      )
    }
  }

  const nextStep = () => {
    stepHistory.push(currentStep)
    setCurrentAnswer(
      currentAnswer.sort(
        (a, b) => getIndex(a.proceedsTo) - getIndex(b.proceedsTo),
      ),
    )
    addAnswer()
    goToStep(getIndex(currentAnswer[0]?.proceedsTo))
    let answer = answers.filter(
      answer => answer.id === currentAnswer[0]?.proceedsTo,
    )
    setCurrentAnswer(answer[0].answer)
  }

  const previousStep = () => {
    let id = stepHistory.pop()
    goToStep(id)
    let answer = answers.filter(answer => answer.id === questions[id].id)
    setCurrentAnswer(answer[0].answer)
  }

  const skipStep = (id) => {
    let answer = answers.filter(answer => answer.id === id)
    setCurrentAnswer(answer[0].answer)
    goToStep(getIndex(id))
  }

  const submitAnswers = (location = '/signup') => {
    let actions = []

    answers.map((ans) => {
      if (ans.length !== 0) {
        ans.answer.map(answer => actions.push(answer.actionItems))
      }
    })

    setQuizActions([...new Set(actions.flat())])
    window.location.replace(location)
  }

  const renderQuestion = (question) => {
    if ((question?.type === 'steppedRange' || question?.type === 'percentageRange') && currentAnswer.length === 0) {
      setCurrentAnswer([question?.answers[0]])
    }

    switch (question?.type) {
      case 'singleSelect':
        return (
          <RadioField
            question={question}
            currentAnswer={currentAnswer}
            setCurrentAnswer={setCurrentAnswer}
            skipStep={skipStep}
          />
        )
      case 'multiSelect':
        return (
          <CheckboxField
            question={question}
            currentAnswer={currentAnswer}
            setCurrentAnswer={setCurrentAnswer}
            skipStep={skipStep}
          />
        )
      case 'steppedRange':
        return (
          <RangeSegmentedField
            question={question}
            currentAnswer={currentAnswer}
            setCurrentAnswer={setCurrentAnswer}
            skipStep={skipStep}
          />
        )
      case 'percentageRange':
        return (
          <RangePercentageField
            question={question}
            currentAnswer={currentAnswer}
            setCurrentAnswer={setCurrentAnswer}
            skipStep={skipStep}
          />
        )
      default:
        return null
    }
  }

  const renderFooter = () => (<WizardFooter
    isFirstStep={isFirstStep}
    isLastStep={isLastStep}
    percentage={percentage}
    nextStep={nextStep}
    previousStep={previousStep}
    nextStepDisabled={currentAnswer.length === 0 || isLastStep}
    hasPrevious={hasPrevious}
  />)

  if (!isQuestionsValid) {
    return null
  }

  return (
    <div className="container relative max-w-3xl mt-10 mb-32 xl:px-0">
      {currentQuestion.type === 'interstitial' ? (
        <div id={currentQuestion.quizStep} data-question-id={currentQuestion.quizStep}>
          <Interstitial
            step={currentQuestion}
            goToStep={goToStep}
            getIndex={getIndex}
          >
            {isLastStep ? (
              <>
                <div className='flex flex-col gap-8 mb-8 lg:gap-10 md:mt-8 shrink-0'>
                  <button
                    className="btn btn--primary btn--wide"
                    onClick={() => submitAnswers('/sign-up')}
                  >
                    Sign Up and View Plan
                  </button>
                  <button
                    className="btn btn--text-link"
                    onClick={() => submitAnswers('/your-action-plan')}
                  >
                    Skip Sign Up and View Plan
                  </button>
                </div>

                {renderFooter()}
              </>
            ) : null}
          </Interstitial>
        </div>
      ) : (
        <fieldset id={currentQuestion.quizStep} data-question-id={currentQuestion.quizStep}>
          <StepHeader
            title={currentQuestion?.title}
            description={currentQuestion?.description}
          />
          <div className="max-w-[600px] mx-auto">
            {renderQuestion(currentQuestion)}
            {currentQuestion?.allowSkip && currentQuestion?.skipToQuestion && (
              <SkipStepButton
                skip={() => skipStep(currentQuestion?.skipToQuestion)}
              />
            )}
            {renderFooter()}
          </div>
        </fieldset>
      )}
    </div>
  )
}

export default Quiz

Quiz.propTypes = {
  questions: PropTypes.arrayOf(questionType).isRequired,
}

if (document.getElementById('quiz')) {
  const container = document.getElementById('quiz')

  const props = Object.assign({}, container.dataset)
  const root = createRoot(container)

  props.questions = JSON.parse(props.questions)

  root.render(<Quiz {...props} />)
}
