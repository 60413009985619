import PropTypes from 'prop-types'
import {useEffect, useState} from 'react'

const FilterList = ({actionTypes, filters, setFilters, setShowFilter}) => {
  let params = new URLSearchParams(window.location.search)

  const [mobileFilters, setMobileFilters] = useState([...filters])
  const [assertiveText, setAssertiveText] = useState('')
  const [assertiveAction, setAssertiveAction] = useState('')

  const updateFilters = (actionType) => {
    if (filters.includes(actionType)) {
      setFilters(items => items.filter(item => item !== actionType))
      setAssertiveAction(`${actionType} filter removed`)
    } else {
      setFilters([...filters, actionType])
      setAssertiveAction(`${actionType} filter selected`)
    }
  }

  const updateMobileFilters = (actionType) => {
    if (mobileFilters.includes(actionType)) {
      setMobileFilters(items => items.filter(item => item !== actionType))
    } else {
      setMobileFilters([...mobileFilters, actionType])
    }
  }

  const filterActionItems = () => {
    setFilters(mobileFilters)
  }

  useEffect(() => {
    setAssertiveText(`${assertiveAction}. Applied filters: ${filters.toString()}`)
  }, [filters])

  return (
    <div className="flex flex-col">
      <div aria-live="assertive" className="sr-only">{assertiveText}</div>

      <ul className="lg:gap-2.5 gap-2 pb-7 flex flex-wrap lg:flex-col">
        {actionTypes &&
          actionTypes.map(actionType => (
            <li key={actionType.id}>
              <button
                className={`hidden lg:block px-5 rounded-full py-2.5 utility-button-label-md focus:outline-none focus-visible:outline focus-visible:outline-blue-800 focus-visible:outline-offset-2 ${
                  filters.includes(actionType.title)
                    ? 'bg-blue-800 text-white'
                    : 'btn--outline-secondary'
                } `}
                onClick={() => updateFilters(actionType.title)}
              >
                {actionType.title}
              </button>

              <button
                className={`lg:hidden px-5 rounded-full py-2.5 utility-button-label-md focus:outline-none focus-visible:outline focus-visible:outline-blue-800 focus-visible:outline-offset-2 ${
                  mobileFilters.includes(actionType.title)
                    ? 'bg-blue-800 text-white'
                    : 'btn--outline-secondary'
                } `}
                onClick={() => updateMobileFilters(actionType.title)}
              >
                {actionType.title}
              </button>
            </li>
          ))}
      </ul>

      {filters.length !== 0 && (
        <div className="mb-6 border-b border-blue-800"/>
      )}

      <div className="flex self-center gap-4 ">
        <button
          className="btn btn--primary lg:hidden"
          onClick={() => {
            filterActionItems()
            setShowFilter(false)
          }}
        >
          update list
        </button>
        <button
          className="text-blue-800 utility-button-label-md lg:hidden"
          onClick={() => {
            setMobileFilters([])
            setFilters([])
            setShowFilter(false)
          }}
        >
          Clear All
        </button>
        {filters.length !== 0 && (
          <button
            className="hidden btn btn--text-link lg:block"
            onClick={() => {
              setFilters([])
              if (params.has('category')) {
                params.delete('category')
                window.history.pushState('object', document.title, location.href.split('?')[0])
              }
            }}
          >
            Clear All
          </button>
        )}
      </div>
    </div>
  )
}

FilterList.propTypes = {
  actionTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
  filters: PropTypes.arrayOf(PropTypes.string),
  setFilters: PropTypes.func.isRequired,
  setShowFilter: PropTypes.func,
}

export default FilterList
