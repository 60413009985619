import PropTypes from 'prop-types'

const RangeTrack = ({props, children}) => (
  <div
    {...props}
    className="rounded-full h-1.5 bg-gray-300 w-full max-w-[300px] md:max-w-sm mx-auto"
  >
    {children}
  </div>
)

RangeTrack.propTypes = {
  props: PropTypes.object,
  children: PropTypes.element,
}

export default RangeTrack
